import Vue from 'vue';
import VueRouter from 'vue-router';
import Guide from '@/views/guide';

Vue.use(VueRouter);

const routes = [
  {
    // 网站首页
    path: '/',
    name: 'guide',
    component: Guide,
  },
  {
    // 网站首页
    path: '/home',
    name: 'homepage',
    component: () => import('../views/homepage.vue'),
  },
  {
    // 网站头部导航
    path: '/head-nav',
    name: 'head-nav',
    component: () => import('../views/head-nav.vue'),
  },
  {
    // 网站底部
    path: '/footers',
    name: 'footers',
    component: () => import('../views/footers.vue'),
  },
  {
    // 产品分类
    path: '/product-sort',
    name: 'product-sort',
    component: () => import('../views/product-sort.vue'),
  },
  {
    // 产品详情
    path: '/product-details/:productId',
    name: 'product-details',
    props: true,
    component: () => import('../views/product-details.vue'),
  },
  {
    // 关于我们
    path: '/about-qualifications',
    name: 'about',
    component: () => import('../views/about-qualifications.vue'),
  },
  {
    // 关于我们-公司历程
    path: '/about-development',
    name: 'about-development',
    component: () => import('../views/about-development.vue'),
  },
  {
    // 关于我们-公司简介
    path: '/about-introduce',
    name: 'about-introduce',
    component: () => import('../views/about-introduce.vue'),
  },
  {
    // 关于我们-公司理念
    path: '/about-idea',
    name: 'about-idea',
    component: () => import('../views/about-idea.vue'),
  },
  {
    // 关于我们-公司理念详情
    path: '/about-idea-details/:id',
    name: 'about-idea-details',
    props: true,
    component: () => import('../views/about-idea-details.vue'),
  },
  {
    // 关于我们-公司目标
    path: '/about-target',
    name: 'about-target',
    component: () => import('../views/about-target.vue'),
  },
  {
    // 关于我们-公司新闻
    path: '/about-news',
    name: 'about-news',
    component: () => import('../views/about-news.vue'),
  },
  {
    // 关于我们-公司新闻详情
    path: '/about-news-details/:id',
    name: 'about-news-details',
    props: true,
    component: () => import('../views/about-news-details'),
  },
  {
    // 关于我们-供应商合作
    path: '/about-cooperation',
    name: 'about-cooperation',
    component: () => import('../views/about-cooperation.vue'),
  },
  {
    // QHSE
    path: '/qhse',
    name: 'qhse',
    component: () => import('../views/qhse.vue'),
  },
  {
    // 品质保证
    path: '/quality-assurance',
    name: 'quality-assurance',
    component: () => import('../views/quality-assurance.vue'),
  },
  {
    // 品质保证详情
    path: '/quality-assurance-details/:id',
    name: 'quality-assurance-details',
    component: () => import('../views/quality-assurance-details.vue'),
    props(req) {
      return {
        ...req.params,
      };
    },
  },
  {
    // 生产能力
    path: '/throughput',
    name: 'throughput',
    component: () => import('../views/throughput.vue'),
  },
  {
    // 市场分析
    path: '/market-analysis',
    name: 'market-analysis',
    component: () => import('../views/market-analysis.vue'),
  },
  {
    // 联系我们
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue'),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.query.lang && !to.query.lang) {
    console.log((from.query.lang));
    next({
      query: from.query,
    });
  } else {
    next();
  }
});

export default router;
