<template>
  <div id="app">
    <router-view />
    <notifications
      position="top center"
      class="mask"
    />
  </div>
</template>

<script>

export default {
  watch: {
    $route(value) {
      const lang = value.query?.lang;
      if (lang) {
        this.$i18n.locale = lang;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: 'Microsoft YaHei' !important;

  .mask {
    ::v-deep .notification-title {
      text-align: center;
    }
  }
}
</style>
