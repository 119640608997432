<template>
  <div class="guide">

    <div class="guide-box">
      <div class="wrap">
        <div class="guide-head">
          <div>
            <img
              src="@/assets/image/guide/head-icon.png"
              alt=""
            >
          </div>
          <div>
            <img
              src="@/assets/image/guide/logo.png"
              alt=""
            >
          </div>
        </div>
      </div>

      <div class="guide-box">

        <div class="guide-content"
         :class="$i18n.locale === 'en'?'guide-content-en':''"
        >
          <h3 class="guide-content-title"> {{$t('guide.title')}}</h3>
          <!-- <div class="guide-content-info">
            {{$t('guide.info')}}
          </div>
          <div class="guide-content-details">
            {{$t('guide.details')}}
          </div> -->
          <div class="left-icon">
            <img
              src="@/assets/image/guide/left-icon.png"
              alt=""
            >
          </div>

          <div class="btn">
            <router-link to="/home">
            {{$t('guide.entrance')}} <i class="iconfont icon-youbianjiantou1"></i>
            </router-link>
          </div>

          <div class="language">
            <button
              v-for="(item, index) in locales"
              :key="index"
              href=""
              @click.prevent="onClickLanguage(item.id,index)"
              :style="LanguageIndex === item.id?'color:#fff;border: 1px solid #fff;':''"
            >
              <span>{{item.name}}</span>
            </button>
          </div>

          <div class="footer-icon">
            <img
              src="@/assets/image/guide/footer-icon.png"
              alt=""
            >
          </div>
        </div>

        <div class="guide-right">
          <img
            src="@/assets/image/guide/right-icon.png"
            alt=""
          >
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      LanguageIndex: 'cn',
      locales: [
        {
          id: 'cn',
          name: '中文',
        },
        {
          id: 'en',
          name: 'EN',
        },
      ],
    };
  },
  methods: {
    onClickLanguage(languageID) {
      this.LanguageIndex = languageID;
      if (languageID === 'en') {
        if (this.$i18n.locale === 'en') {
          return;
        }

        this.$i18n.locale = 'en';
        this.$router.replace({
          query: {
            lang: this.$i18n.locale,
          },
        });
      } else if (languageID === 'cn') {
        if (this.$i18n.locale === 'cn') {
          return;
        }

        this.$i18n.locale = 'cn';
        this.$router.replace({
          query: {
            lang: this.$i18n.locale,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.guide {
  background: url('~@/assets/image/guide/banner.png') no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  background-color: #cccccc;
  height: 100vh;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .guide-box {
    .wrap {
      .guide-head {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    .guide-box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .guide-content {
        width: 640px;
        &-title {
          padding: 8px 16px;
          background: #fff;
          color: #0f2d4d;
          font-size: 36px;
          font-weight: 500;
          display: inline-block;
        }

        &-info {
          font-size: 24px;
          font-weight: 900;
          margin-top: 20px;
          color: #fff;
        }

        &-details {
          font-size: 14px;
          line-height: 24px;
          margin-top: 24px;
          color: #fff;
        }

        .left-icon {
          margin-top: 24px;
        }

        .btn {
          margin-top: 40px;

          a {
            font-size: 18px;
            padding: 13px 34px;
            border-radius: 30px;
            background: #294867;
            opacity: 0.6;
            outline: none;
            color: #fff;
            border: 2px solid #fff;

            &:hover {
              background: #ffbf00;
              opacity: 1;
              color: #333;
              border: 2px solid transparent;
              cursor: pointer;
            }

            i {
              margin-left: 20px;
            }
          }
        }

        .language {
          margin-top: 4rem;

          button {
            cursor: pointer;
            width: 38px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: transparent;
            outline: none;
            color: #818387;
            border: 1px solid #818387;
            border-radius: 10px;
            margin-right: 14px;
            font-size: 15px;

            &:hover {
              color: #fff;
              border: 1px solid #fff;
            }
          }
        }

        .footer-icon {
          margin-top: 10rem;
        }
      }

      .guide-content-en{
        .guide-content-title{
          font-size:30px;
        }

        .guide-content-info{
          font-size:18px;
          margin-top: 15px;
        }

        .guide-content-details{
          font-size:13px;
          line-height:16px;
          margin-top: 15px;
        }
      }

      .guide-right {
        margin-top: 12.5rem;
      }
    }
  }
}
</style>
