import Vue from 'vue';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';

import mobile from 'is-mobile';
import App from './App.vue';
import router from './router';
import store from './store';
import '@csstools/normalize.css';
import '@/assets/css/index.css';
import { createProvider } from './vue-apollo';
import i18n from './i18n';
import '@/assets/icon/iconfont.css';

if (mobile()) {
  window.location.href = 'https://mobile.leewen.top/';
} else {
  Vue.use(Notifications);

  Vue.use(Vuelidate);

  Vue.config.productionTip = false;

  new Vue({
    router,
    store,
    apolloProvider: createProvider(),
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
}
